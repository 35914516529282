.category_top {
    background: #f6f7f9;
    padding-right: 20px;
}
.category_top h1 {
    text-align: left;
    vertical-align: bottom;
    padding-top: 15px;
}
.sortBy {
    padding-top: 15px;
    text-align: right;
}
.card_brand_list {
    margin: 0.5rem 0 !important;
    /* height: 14rem; */
    min-height: 190px;
}
.brand_card_body {
    display: flex;
    align-items: center !important;
}
.img_thumb {
    width: auto !important;
    padding: 5px; /* Some padding */
    max-height: 150px; /* Set a small width */
    max-width: 150px;
}
.image_caption_text {
    font-size: 14px !important;
      text-align: center;
}
.card_model_list {
    margin: 0.5rem 0 !important;
    height: 250px;
}
.model_card_body {
    display: flex;
    align-items: center !important;
}
.card_product_form {
    margin: 5px 0 !important;
}
.prd_pg_img_container {
    max-height: 500px;
}
.text_al_c {
    text-align: center !important;
}
.blue_text {
    color: #0070ae;
}
.text_al {
    text-align: left !important;
}
.text_ar {
    text-align: right !important;
}
.prd_pg_img {
    width: auto !important;
    max-width: 312px !important;
    max-height: 331px !important;
}
.card_text {
    margin: 0 auto;
}

@media all and (max-width: 768px) {
  .prd_pg_img {
    max-width: 312px !important;
    max-height: 331px !important;
	}
}