@import "bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #000;
  font-size: 16px;
  line-height: 1.8;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}
.card {
  margin: 0 2rem;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}
.card footer {
  text-align: right;
  background: none;
}
.card-text img {
  width: 100%;
}
.inListing {
  margin: 10px !important;
}
.model-list {
  height: 250px;
}
h1 {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  color: #222222;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.4 !important;
}
h2 {
  font-family: "Inter", sans-serif;
  font-size: 26px;
  color: #222222;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.4 !important;
}
h3{
  font-size: 22px !important;
}
h4{
  font-size: 20px !important;
}
h5{
  font-size: 16px !important;
}
h3, h4, h5, h6{
  color: #222222;
  line-height: 1.4 !important;
}
p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #222222;
  text-align: left;
}
.f_l {
  float: left;
  width: 33%;
}
label {
  text-align: left;
  display: block;
}
.mr0 {
  margin: 0 !important;
}
.d2d-card-list {
  max-height: 325px;
}
.d2d-card-body {
  display: flex;
  align-items: center !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.noBorder {
  border: none !important;
}
.white_link {
  color: #fff !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
  background-color: #fff;
  border-radius: 8px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.MuiAutocomplete-root fieldset{
  border: none !important;
}
.MuiAutocomplete-root .MuiAutocomplete-popupIndicator{
  background: url(/images/search-white.svg) center center no-repeat #BC1C5C !important;
  background-size: 16px auto;
  height: 40px;
  width: 40px;
  border-radius: 6px;
}
.MuiAutocomplete-root .MuiOutlinedInput-input {
  padding: 10px 14px;
}
.MuiAutocomplete-root .MuiAutocomplete-endAdornment {
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 7px !important;
}
.MuiAutocomplete-root .MuiInputBase-input{
  font-size: 16px;
}
.MuiAutocomplete-root .MuiInputBase-input::placeholder {
  color: #272727;
}
.custom-placeholder .MuiInputBase-input::placeholder {
  color: #272727;
  opacity: 1;
}
.MuiAutocomplete-root .MuiAutocomplete-popupIndicator span{
  display: none !important;
}
.MuiInputBase-input {
  font-family: "Inter", sans-serif !important;
}
.custom-placeholder .MuiAutocomplete-popupIndicatorOpen{
  transform: none !important;
}
.PrivateNotchedOutline-root-2 {
  border: none !important;
}
.static-page ul li {
  text-align: left;
}
.strikethrough {
  text-decoration: line-through;
}
.mobile_view {
  display: none !important;
}
.desktop_view {
  display: block !important;
}
.sticky_bar {
  width: 100%;
  text-align: center;
  background: #bc1c5c;
  color: #fff;
  z-index: 9999;
  font-size: 16px;
  font-weight: 600;
}
.font12 {
  font-size: 12px;
}
@media all and (max-width: 480px) {
  .font12 {
    float: right;
    font-size: 12px;
    margin-right: 14%;
    text-align: right;
  }
}
@media all and (max-width: 375px) {
  .font12 {
    margin-right: 5%;
  }
}
@media all and (max-width: 320px) {
  .font12 {
    font-size: 10px;
  }
}
/* About Us section start */
.about_us_to_text {
  overflow: hidden;
}
.about_us_to_text p {
  float: left;
  display: block;
  width: 70%;
  margin: 0;
  padding: 0;
}
.about_us_to_text a {
  color: #bc285c;
}
.about_us_to_text span {
  float: right;
  width: 29.7%;
  display: block;
  text-align: right;
}
.about_us_body {
  margin-top: 20px;
  text-align: left;
}
.about_us_body ul {
  padding: 0;
}
.about_us_body > ul li {
  list-style: none;
  float: none;
  overflow: hidden;
}
.about_us_body > ul li h3 {
  margin-bottom: 0;
}
.about_us_body > ul li p {
  margin-top: 0;
}
.about_us_body > ul li > p:last-child {
  margin-bottom: 20px;
}
.about_us_body > ul li:last-child {
  margin-top: 20px;
}
.about_us_body > ul li:last-child,
.about_us_body > ul li:last-child img {
  margin-bottom: 0;
}
.about_us_body > ul li ul li {
  background: url(/images/li-img.jpg) no-repeat 0 45%;
  padding-left: 20px;
  margin-bottom: 0;
}
.about_us_body > ul li a.bwWrapper {
  display: block;
  float: left;
  border: 4px solid #d6d6d6;
  margin-right: 20px;
  margin-bottom: 20px;
}
.about_us_body > ul li:nth-child(2) > ul li:last-child {
  margin-top: 0;
}
.about_us_body > ul li:nth-child(2n + 2) a.bwWrapper {
  float: right;
  margin-left: 30px;
  margin-right: 0;
  display: block;
  overflow: hidden;
}
.about_us_body a {
  color: #bc285c;
}
.bwWrapper {
  position: relative;
  display: block;
}
.about_us_body > ul li:nth-child(2n + 2) a canvas {
  left: 0 !important;
}
.about_us_body img {
  width: auto;
}
.about_us_body h3 {
  text-align: left;
}
/* About us page end */
/* Why Budli Page start */
.whyBudli {
  float: left;
  width: 28%;
}
.whyBudli2 {
  float: right;
  width: 28%;
}
.tright {
  overflow: hidden;
}
.whyBudli:nth-child(2) {
  text-align: center;
  width: 44%;
}
.whyBudli .tright .f_l,
.whyBudli2 .tright .f_l {
  background: url(/images/riskfree.png) top center no-repeat;
  width: 100%;
  padding-top: 70px;
  margin-bottom: 20px;
  text-align: center;
}
.whyBudli .tright:nth-child(2) .f_l {
  background: url(/images/environment.png) top center no-repeat;
}
.whyBudli .tright:nth-child(3) .f_l {
  background: url(/images/value.png) top center no-repeat;
}
.whyBudli .tright .f_l span,
.whyBudli2 .tright .f_l span,
.whyBudli3 .tright .f_l span,
.whyBudli2 .tright .f_l span {
  display: block;
  margin-bottom: 10px;
  font-size: 1.17em;
  color: #3594c4;
  font-weight: bold;
}
.whyBudli2 .tright:first-child .f_l {
  background: url(/images/speed.png) top center no-repeat;
}
.whyBudli2 .tright:nth-child(2) .f_l {
  background: url(/images/convenience.png) top center no-repeat;
}
.whyBudli2 .tright:nth-child(3) .f_l {
  background: url(/images/simplicity.png) top center no-repeat;
}
.whyBudli3 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.whyBudli3 .tright .f_l {
  background: url(/images/transparent.png) top center no-repeat;
  width: 100%;
  padding-top: 70px;
  margin: auto;
}
.whyBudli > img {
  margin-bottom: 14px;
}
/* Why Budli end */
/* How it works start */
a.request-button {
  display: block;
  width: 16%;
  /* height: 40px; */
  border-radius: 3px;
  -webkit-border-radius: 3px;
  background: #bc285c;
  font-size: 18px;
  margin: auto;
  line-height: 40px;
  color: #fff !important;
  text-align: center;
  text-decoration: none;
}
.video_container {
  float: none;
  margin: auto;
  text-align: center;
}
.step_section {
  overflow: hidden;
}
a {
  color: #ca557f !important;
  text-decoration: none !important;
  cursor: pointer;
}
.how-works-h3 {
  margin-top: 16px;
  text-align: left;
}
.step_section + .request-button {
  color: #fff;
}
.step_section .all_step_container {
  margin-right: 2%;
  margin-top: 20px;
  width: 32%;
  overflow: hidden;
  float: left;
}
.step_section .all_step_container .all_step {
  margin-top: 0;
  background: #ca557f;
  padding: 7%;
  float: none;
}
.step_section .all_step_container:last-child {
  margin-right: 0;
}
.step_section .all_step_container .all_step p {
  color: #fff;
}
.step_section .all_step_container:nth-child(2) .all_step {
  background: #00b297;
}
.step_section .all_step_container:nth-child(3) .all_step {
  background: #e4af0d;
}
.all_step img {
  width: auto;
}
.all_step_container p {
  text-align: justify;
  margin-top: 15px;
}
/** For forms **/
label {
  display: inline-block;
  /* margin-bottom: 0.5rem; */
}
.button-label {
  width: 200px;
  border: 1px solid;
  border-color: rgba(112, 112, 112, 0.12);
  margin-right: 20px;
  background-color: #fafafa;
  border-radius: 6px;
  height: 40px;
  cursor: pointer;
  padding: 7px;
  text-align: left;
}
.padl-9 {
  padding-left: 9px;
}
.mr-16 {
  margin-right: 16px !important;
}
.lh-40 {
  line-height: 40px;
}
.dispb {
  display: block !important;
}
.radio-input {
  height: 100%;
  width: 50px;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
.image-radio {
  border: 1px solid;
  border-color: rgba(112, 112, 112, 0.12);
  margin-right: 20px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}
.reqfrm .image-radio{
  padding: 0 0 15px;
  margin: 0;
  display: block;
}
.image-radio > [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.image-radio > [type="radio"]:checked + img {
  outline: 2px solid #28a745;
}
.reqfrm .image-radio  > [type="radio"]:checked + img {
  outline: none !important;
}
.image-radio1 > label {
  border: 1px solid;
  border-color: rgba(112, 112, 112, 0.12);
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  padding: 0;
  font-size: 0.75rem;
  line-height: 15px;
  min-height: 190px;
}
.image-radio1 > [type="radio"], .image-radio1 > [type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.checkspace input{
  margin-right: 6px;
}
.image-radio1 > label.checked_radio {
  border-color: #ca557f;
}
.imgradiotitle{
  background-color: #F4F4F4;
  min-height: 80px;
  border-radius: 0 0 6px 6px;
  width: 100%;
}
.image-radio1 input:checked + label .imgradiotitle{
  background-color: #BC1C5C;
  color: #fff;
}
.image-radio1 input:checked + label{
  border-color: #BC1C5C;
}
/* FAQ PAGE START */
.card-header-pills {
  margin-right: -1rem;
  border-right: none;
}
.nav-pills .nav-link {
  border-radius: 0;
  position: relative;
  z-index: 1;
}

.vertical-tab .nav-link {
  width: 100%;
}
.vertical-tab a {
  color: #ffffff;
}
.vertical-tab .nav-item {
  display: block;
  margin: 0;
  padding: 0 !important;
  border-right: none;
  cursor: pointer;
  float: none;
  background: #017ab3;
  border-bottom: 1px dashed #0094d9;
}
.nav-pills .nav-link.active {
  background-color: #fff;
  color: #017ab3;
  border: 1px dashed #0094d9;
  border-bottom: none;
  border-right: none;
}
/* FAQ Page end */
/* Testimonial Page start */
.page-link {
  cursor: pointer;
}

.infinite-scroll-component {
  overflow: hidden !important;
}
.f_l h3 {
  text-align: left;
}
.btn-primary {
  color: #fff !important;
}
.white-link {
  color: #fff !important;
}
._YLx_9 {
  bottom: 0px !important;
  right: 60px !important;
}
@media all and (max-width: 768px) {
  .static_page {
    margin: 0 !important;
  }
}
table {
  border-collapse: collapse;
  background: rgba(255, 255, 255, 0.4);
}

th,
td {
  border: 1px solid #000;
  padding: 0.75rem;
  text-align: center;
}

th {
  font-weight: bold;
  white-space: nowrap;
  background: #000;
  color: #fff;
}

tr:first-of-type th:not(:last-child) {
  border-right-color: transparent;
}

tr:first-child th:first-child,
tr:not(:first-child):not(:last-child) th {
  border-bottom-color: transparent !important;
}
a.request-button {
  display: inline-block;
  width: auto;
  padding: 5px;
  margin: 10px;
  font-size: 14px;
}
/** For Myaccount **/
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.t_c_type {
  text-align: left;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.bg-blue {
  background-color: #017ab3 !important;
}
.profile-nav {
  margin: 0 !important;
}
.profile-nav a {
  color: #fff !important;
}

.b4b_contact_form {
  margin: 0 !important;
}

.brdcrumbs {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  list-style: none;
  display: inline-flex;
}
.brdcrumbs li {
  padding-left: 5px;
}
.brdcrumbs li:first-child {
  padding-left: 0;
}
.brdcrumbs li::after {
  content: " > ";
}
.brdcrumbs li:last-child::after {
  content: "";
}
/** For Resposive **/
@media all and (min-width: 1024px) {
  .myacc_container {
    margin-left: 240px;
  }
}
@media all and (max-width: 823px) {
  .about_us_to_text p,
  .about_us_body img {
    width: 100%;
  }
  .about_us_body span img {
    width: auto;
  }
}
@media all and (max-width: 768px) {
  .about_us_body iframe {
    width: 100% !important;
  }
  .whyBudli,
  .whyBudli2 {
    float: none;
    width: 100%;
  }
  .whyBudli:nth-child(2) {
    text-align: center;
    width: 100%;
  }
  .step_section .all_step_container {
    float: none;
    width: 100%;
  }
  .video-responsive iframe {
    width: 100% !important;
  }
  a.request-button {
    width: 45%;
  }
  .static-page {
    margin: 0 !important;
  }
  .static-page img {
    width: 100% !important;
  }
  .contcat_form {
    margin: 0 !important;
  }
  .brdcrumbs {
    font-size: 10.5px;
  }
  .mobile_view {
    display: block !important;
  }

  .desktop_view {
    display: none !important;
  }
}
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}
.mnutog .MuiButtonBase-root{
  color: #0474B4;
}
.gap-1{
  gap: 5px;
}
.gap-2{
  gap: 10px;
}
.gap-3{
  gap: 15px;
}
.gap-4{
  gap: 20px;
}
.gap-5{
  gap: 25px;
}
.submnu{
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 110%;
  background-color: #f8f9fa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  padding: 7px 0;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.submnu:before{
  content: '';
  width: 0px;
   height: 0px;
   border-style: solid;
   border-width: 0 7.5px 10px 7.5px;
   border-color: transparent transparent #f8f9fa transparent;
   display: block;
   position: absolute;
   bottom: 100%;
   left: 50%;
   transform: translateX(-50%);
}
.submnu li a{
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 15px;
  font-size: 14px;
  white-space: nowrap;
  color: #170F49;
}
.submnu li a:hover{
  color: #0474B4 !important;
}
.navbar-nav > ul > li > a{
  color: #222 !important;
}
.navbar-nav > ul > li:hover .submnu{
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.body-font{
  font-size: 15px !important;
}
.curser-pointer{
  cursor: pointer;
}
header.header{
  background-color: #fff;
}
header.header.fixed{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.sellwidgt a{
  background-color: #BC1C5C;
  overflow: hidden;
}
.buywidgt a{
  background-color: #0474B4;
  overflow: hidden;
}
.sellwidgt a img, .buywidgt a img{
  -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.sellwidgt a:hover img, .buywidgt a:hover img{
  transform: scale(1.05);
}
.container, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1320px;
}
.fw-700{
  font-weight: bold;
}
.fw-600{
  font-weight: 600;
}
.fw-500{
  font-weight: 600;
}
.fw-400{
  font-weight: 600;
}
.slidenav{
  width: 300px;
}
.btnico{
  margin: 0;
  padding: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: rgba(0,0,0,0) !important;
}
.MuiDrawer-root.MuiDrawer-modal{
  z-index: 1000 !important;
}
.loginregmodal .nav-tabs{
  display: flex;
  justify-content: center;
  border: none !important;
  padding: 10px;
  background-color: #f0f7fb;
  border-radius: 4px;
}
.loginregmodal .nav-tabs a{
  width: 50%;
  border-radius: 4px !important;
  border: none !important;
  text-align: center;
}
.loginregmodal .nav-tabs a.active{
  background-color: #BC1C5C;
  color: #fff !important;
}
.btn-default{
  background-color: #0474B4 !important;
  color: #fff !important;
  border: 1px solid #0474B4 !important;
}
.landinghero .swiper-button-prev, .landinghero .swiper-button-next{
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0474B4;
  color: #fff;
  border-radius: 2px;
}
.landinghero .swiper-button-prev:after, .landinghero .swiper-button-next:after{
  color:#fff;
  font-size: 16px;
}
.landinghero .swiper-button-prev{
  left: 15px;
}
.landinghero .swiper-button-next{
  right: 15px;
}
.landinghero .swiper-slide img{
  cursor: pointer;
}
.landinghero .swiper-pagination-bullet, .testisec .swiper-pagination-bullet{
  background-color: #0474B4;
  opacity: 1;
}
.landinghero .swiper-pagination-bullet-active, .testisec .swiper-pagination-bullet-active{
  background-color: #BC1C5C;
}
.text-dark{
  color: #222 !important;
}
.text-default{
  color: #0474B4 !important;
}
.testisec .testitxt{
  min-height: 150px;
}
.testiimg{
  min-width: 55px;
  max-width: 55px;
  min-height: 55px;
  max-height: 55px;
  border-radius: 100%;
  border: none;
}
.testisec .swiper{
  padding: 90px 5px 5px 5px;
}
.shadow-sm {
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1) !important;
}
.testisec .swiper-pagination, .videosec .swiper-pagination{
  position: static;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.testisec .swiper-button-prev, 
.testisec .swiper-button-next{
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BC1C5C;
  color: #fff;
  border-radius: 2px;
  transform: none !important;
  top: 40px;
}
.testisec .swiper-button-prev:after, 
.testisec .swiper-button-next:after, 
.videosec .swiper-button-prev:after, 
.videosec .swiper-button-next:after{
  color:#fff;
  font-size: 16px;
}
.testititsec{
  position: absolute;
  top: 0;
  left: 0;
}
.videosecinr{
  position: absolute;
  top: 16px;
  left: 0;
}
.testisec .swiper-button-prev, .videosec .swiper-button-prev{
  left: inherit;
  right: 70px;
}
.testisec .swiper-button-next, .videosec .swiper-button-next{
  right: 5px;
  left: inherit;
}
.clsmodal{
  position: absolute;
  right: 15px;
  top: 10px;
  opacity: 0.6;
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videosec .swiper {
  padding: 70px 0 0 0;
}
.videosec .swiper-button-prev, 
.videosec .swiper-button-next{
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BC1C5C;
  color: #fff;
  border-radius: 2px;
  transform: none !important;
  top: 0;
  margin: 0;
}
.videosec .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #BC1C5C;
}
.row10{
  margin-left: -10px;
  margin-right: -10px;
}
.row10 > div{
  padding-left: 10px;
  padding-right: 10px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-20{
  margin-bottom: 20px;
}
.collagegrid > div:nth-child(1){
  max-width: 34.88%;
}
.collagegrid > div:nth-child(2){
  max-width: 25.1%;
}
.bg-default-red{
  background-color: #BC1C5C;
}
.bg-default-blue{
  background-color: #0474B4;
}
.text-default-red{
  color: #BC1C5C !important;
}
.text-default-blue{
  color: #0474B4 !important;
}
.bg-default-red.videosec .swiper-button-prev, 
.bg-default-red.videosec .swiper-button-next{
  background-color: #0474B4;
}
.bg-default-red.videosec .swiper-pagination-bullet{
  opacity: 1;
  background-color: #fff;
}
.bg-default-red.videosec .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #0474B4;
}
.BrainhubCarousel__dots {
  gap: 8px;
  margin-top: 10px !important;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot{
  padding: 0 !important;
  opacity: 1 !important;
  border-radius: 100%;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot:before{
  height: 8px !important;
  width: 8px !important;
  background-color: #D9D9D9 !important;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before{
  background-color: #BC1C5C !important;
}
.accordiontabsec .card-header{
  padding: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
.accordiontabsec .card-header button{
  border: none !important;
  padding: 10px 30px 10px 0;
  background-color: rgba(0,0,0,0);
  font-size: 18px;
  color: #222;
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
}
.accordiontabsec .card-header.active button{
  color: #BC1C5C;
}
.accordiontabsec .card-header button svg{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.accordiontabsec .nav-tabs a{
  padding: 0;
  border: none !important;
  background-color: rgba(0,0,0,0) !important;
  font-size: 18px !important;
  color: #222 !important;
  font-weight: 500 !important;
}
.accordiontabsec .nav-tabs a.active{
  color: #0474B4 !important;
}
.accordiontabsec .nav-tabs{
  gap: 10px;
  position: absolute;
  right: 0;
  top: -42px;
}
.blgthmb h5{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.featuredblogsec .swiper-pagination{
  position: static;
  margin-top: 10px;
}
.featuredblogsec .swiper-pagination-bullet{
  background-color: #D9D9D9;
  opacity: 1;
}
.featuredblogsec .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #BC1C5C;
}
.nsltr .btn {
  background-color: #2B2B2B;
  color: #fff;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  border: 2px solid #2B2B2B;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  height: 45px;
}
.nsltr .form-control{
  border-radius: 0;
  border: 2px solid #2B2B2B;
  font-size: 12px;
  background-color: #B3D7E8;
  box-shadow: none !important;
  outline: none !important;
  height: 45px;
}
.borderbottom{
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
.sellbnr {
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  padding: 30px 0;
  position: relative;
}
.rounded-1rem{
  border-radius: 1rem;
}
.bg-light2{
  background-color: #F6F6F6;
}
.h42{
  min-height: 42px;
}
.rounded-05rem{
  border-radius: 0.5rem;
}
.mob-row5{
  margin-left: -10px;
  margin-right: -10px;
}
.mob-row5 > div{
  padding-left: 10px;
  padding-right: 10px;
}
.sellwidgt small, .buywidgt small{
  font-size: 12px;
}
.requestbtn{
  display: inline-flex;
  padding: 12px 25px;
  background-color: #BC1C5C !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 50px;
}
.btnblupill{
  display: inline-flex;
  padding: 10px 25px;
  background-color: #0474B4 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
}
.bg-default-blue.videosec .swiper-pagination-bullet{
  background-color: #fff;
  opacity: 1;
}
.bg-default-blue.videosec .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #BC1C5C;
}
.SellBrandSliderlogo{
  border-radius: 10px;
  background-color: #fff;
}
.breadcumb{
  font-size: 13px;
}
.row8{
  margin-left: -8px;
  margin-right: -8px;
}
.row8 > div{
  padding-left: 8px;
  padding-right: 8px;
}
.readmoretxtbtn{
  background-color: rgba(0,0,0,0) !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  color: #fff !important;
}
.rdmrtxt{
  position: absolute;
  left: 10px;
  top: 100%;
  right: 10px;
  height: calc(100% - 20px);
  overflow: auto;
  background-color: #0474B4;
  color: #fff;
  font-size: 14px;
  padding: 15px;
  border-radius: 0.3rem;
  -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.fastestthmb.active .rdmrtxt{
  top: 10px;
}
.removebtn{
  position: absolute;
  left: 50%;
  height: 30px;
  width: 30px;
  transform: translateX(-50%);
  bottom: 0;
  background-color: #BC1C5C !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  border-radius: 50% 50% 0 0;
}
.accordiontabsec.tabcenter .nav-tabs{
  position: static;
  justify-content: center;
  gap: inherit;
  text-align: center;
}
.accordiontabsec.tabcenter .nav-tabs a{
  width: 15%;
  padding: 12px 5px;
  position: relative;
}
.accordiontabsec.tabcenter .nav-tabs a:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3px;
  background-color: #0474B4;
  opacity: 0;
  visibility: hidden;
}
.accordiontabsec.tabcenter .nav-tabs a.active:after{
  opacity: 1;
  visibility: visible;
}
.swafrm label{
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin: 0;
}
.swafrm .form-control{
  min-height: 50px;
  font-size: 13px;
}
.swafrm .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #CCCCCC;
}
.swafrm .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #CCCCCC;
}
.swafrm .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #CCCCCC;
}
.swafrm .form-control:-moz-placeholder { /* Firefox 18- */
  color: #CCCCCC;
}
.request-button{
  background-color: #BC1C5C !important;
  border-radius: 5px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.shadow-default {
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.08) !important;
}
.contactinfo{
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  border-radius: 0.3rem 0 0 0.3rem;
}
.contactinfo a{
  color: #fff !important;
}
.swafrm .invalid-feedback {
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.2;
}
.brandcircleimg{
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swafaq .MuiAccordionSummary-root .MuiTypography-root{
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
}
.swafaq .MuiIconButton-label {
  color: #000;
}
.swafaq .MuiAccordionSummary-expandIcon{
  transform: none !important;
}
.MuiAccordionDetails-root{
  font-weight: 400 !important;
}
.MuiCollapse-wrapperInner .MuiTypography-body1 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
}
.col5-mob33{
  -moz-box-flex: 0;
  flex: 0 0 20%;
  max-width: 20%;
}
.col5-mob50{
  -moz-box-flex: 0;
  flex: 0 0 20%;
  max-width: 20%;
}
.lightbtn{
  background-color: #F2F8FB !important;
  color: #000 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  font-size: 14px;
}
#my-radio-group{
  color: #BC1C5C;
  font-weight: 600;
}
.border-md-right{
  border-right:1px solid #dee2e6;
}
.radiochkbox .button-label, .radiochkbox .dispb{
  position: relative;
  z-index: 2;
  border: none !important;
  padding-left: 32px;
}
.radiochkbox .dispb{
  padding: 10px 10px 10px 32px;
}
.radiochkbox .button-label input, .radiochkbox .dispb input{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.radiochkbox .button-label span, .radiochkbox .dispb span{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  z-index: -1;
}
.radiochkbox .button-label input:checked + span, .radiochkbox .dispb input:checked + span{
  border-color: #BC1C5C;
}
.radiochkbox .button-label input + span:after, .radiochkbox .dispb input + span:after{
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
  border: 1px solid #ccc;
  border-radius: 100%;
}
.radiochkbox .button-label input:checked + span:after, .radiochkbox .dispb input:checked + span:after{
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
  background-image: url(/images/radio-check.svg);
  background-repeat: no-repeat;
  background-size: 14px auto;
  border-color: #BC1C5C;
  background-position: center center;
}
.bg-light .MuiPaper-root, .bg-light .MuiAccordionSummary-root {
  background-color: rgba(0,0,0,0) !important;
}
.modal-md.modal-dialog-centered .modal-header{
  display: none;
}
button.bg-default-red{
  background-color: #646C7C !important;
}
button.bg-default-blue{
  background-color: #0474B4 !important;
}
.chkfrm label{
  font-size: 15px;
  display: block;
  margin: 0 0 4px;
  font-weight: 500;
  line-height:1.2;
}
.chkfrm .form-control{
  min-height: 50px;
  font-size: 15px;
}
.chkfrm .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #CCCCCC;
}
.chkfrm .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #CCCCCC;
}
.chkfrm .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #CCCCCC;
}
.chkfrm .form-control:-moz-placeholder { /* Firefox 18- */
  color: #CCCCCC;
}
.redtbl table thead tr th{
  background-color: #BC1C5C;
  border: 1px solid #DDDDDD !important;
}
.redtbl table tbody tr td{
  border: 1px solid #DDDDDD;
}
.chkoutfrmwrp .stepfrmbtns{
  padding: 0 1rem 1rem;
}
.tableborder tr td{
  border: 1px solid #DDDDDD;
}
.fontsize20{
  font-size: 20px !important;
}
.accmnu ul{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.accmnu ul li a{
  display: flex;
  align-items: center;
  gap: 10px;
  color: #BC1C5C;
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
}
.accmnu ul li a span{
  width: 20px;
}
.accmnu ul li a.active, .accmnu ul li a.active:hover{
  background-color: #BC1C5C;
  color: #fff !important;
}
.accmnu ul li a.active svg path{
  fill:#fff;
}
.accmnu ul li a:hover{
  background-color: #f1f1f1;
}
.ordrthmbwrp > div:nth-child(1){
  border-right: 1px solid #E9E9EB;
}
.ordrthmbwrp > div:nth-child(2){
  border-right: 1px solid #E9E9EB;
}
.ordrthmbwrp > div:nth-child(3){
  border-right: 1px solid #E9E9EB;
}
.radiocheckbox{
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 0;
  height: 100%;
}
.radiocheckbox input{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  margin: 0;
  padding: 0;
}
.radiocheckbox label{
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #DDDDDD;
}
.radiocheckbox.text-center label{
  text-align: center;
}
.radiocheckbox input:checked + label{
  border: 1px solid #BC1C5C;
}
.radiocheckbox input:checked + label:after{
  content: '';
  position: absolute;
  right: 5px;
  top: 5px;
  height: 15px;
  width: 15px;
  background-image: url(/images/check-red.svg);
  background-repeat: no-repeat;
  background-size: 15px auto;
}
.redtable table Thead tr th{
  background-color:#BC1C5C;
  color: #fff;
  border: 1px solid #DDDDDD;
}
.redtable table Tbody tr td{
  border: 1px solid #DDDDDD;
}
.btnblack{
  background-color: #000 !important;
  color: #fff !important;
  display: flex;
  align-items: center;
  gap: 5px;
}
.uploadbond{
  position: relative;
  z-index: 2;
}
.uploadbond input{
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.uploadbondlbl{
  color: #646464;
}
.fontsize14{
  font-size: 14px;
}
.uploadfile{
  border-radius: 4px;
  border: 1px solid #ced4da;
}
.uploadfile input{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.uploadfile .cf{
  color: #CCCCCC;
  padding: 0 15px;
}
.uploadfile .uf{
  background-color: #B2B2B2;
  padding: 10px 20px;
  color: #fff;
}
.uploadfile .invalid-feedback{
  position: absolute;
  left: 0;
  top: 100%;
}
.blgimg img{
  object-fit: cover;
  height: 220px !important;
  border-radius: 5px;
}
.jss1 {
  z-index: 10;
}
.mnutog .MuiIconButton-root{
  padding: 0 !important;
  background-color: #fff !important;
  margin: 0 !important;
}
.mnutog .MuiIconButton-root .MuiSvgIcon-root {
  width: 2.4rem;
  height: 2.4rem;
}
@media only screen and (min-width: 1199px) and (max-width: 1600px) {
  .navbar-nav>ul{
    gap: 5px;
  }
  .navbar-nav>ul>li>a{
    font-size: 10px;
  }
  .mnusvg svg{
    width: 16px;
  }
  .logo img{
    width: 80px !important;
  }
}

@media screen and (max-width: 1300px) {
  .slidenavlogo img{
    width: 82px !important;
  }
  .slidenav{
    position: relative;
    height: calc(100vh - 155px);
    overflow: auto;
  }
  .slidesubmnu {
    position: absolute;
    width: 100%;
    right: 100%;
    top: 0;
    height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    list-style: none;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  .slidesubmnu.show{
    right: 0;
  }
  .slidenav .mnusvg{
    min-width: 25px;
  }
  .slidenavitem, .slidesubmnu > li > a{
    padding: 13px 15px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  .slidenavitem{
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  .submenuactive.slidenav .slidenavitem{
    transform: translate(100%);
  }
}
@media screen and (max-width: 991px) {
  body {
    font-size: 14px;
    line-height: 1.5;
  }
  .mob-row10{
    margin-left: -10px;
    margin-right: -10px;
  }
  .mob-row10 > div{
    padding-left: 10px;
    padding-right: 10px;
  }
  .mob-row5{
    margin-left: -5px;
    margin-right: -5px;
  }
  .mob-row5 > div{
    padding-left: 5px;
    padding-right: 5px;
  }
  .landinghero .swiper-button-prev, .landinghero .swiper-button-next{
    height: 30px;
    width: 30px;
  }
  .landinghero .swiper-button-prev:after, .landinghero .swiper-button-next:after{
    font-size: 14px;
  }
  .infosec{
    font-size: 12px;
  }
  .testisec .swiper-button-prev, .testisec .swiper-button-next, .videosec .swiper-button-prev, .videosec .swiper-button-next{
    display: none;
  }
  .videosecinr{
    position: static;
  }
  .videosec .swiper {
    padding: 5px 0 0 0;
  }
  .bg-default-red.videosec .swiper-button-prev, .bg-default-red.videosec .swiper-button-next{
    display: flex;
    height: 36px;
    width: 36px;
  }
  .bg-default-red.videosec .videosecinr{
    position: absolute;
    top: 2px;
  }
  .bg-default-red.videosec .swiper {
    padding: 50px 0 0 0;
  }
  .bg-default-red.videosec .swiper-button-prev {
    right: 55px;
  } 
  .whybudly .row > div{
    margin-bottom: 10px !important;
  }
  .accordiontabsec.tabcenter .nav-tabs a{
    width: 20%;
  }
  .col5-mob33{
    -moz-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ordrthmbwrp > div:nth-child(3){
    border-right: none;
  }
  .col5-mob50{
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  
}
@media screen and (max-width: 767px) {
  .landinghero .swiper-button-prev, .landinghero .swiper-button-next{
    display: none;
  }
  .landinghero .swiper-pagination{
    bottom: 0 !important;
  }
  .infosec{
    font-size: 10px;
  }
  .infosec img{
    width: 30px;
  }
  .collagegrid > div{
    max-width: inherit;
    min-width: 100%;
  }
  .collagegrid .mb-20{
    margin-bottom: 16px;
  }
  .bg-default-red.videosec .swiper-slide img{
    width: 100px;
  }
  .whybudly img.rounded-circle{
    width: 60px;
  }
  .whybudly {
    font-size: 12px;
  }
  .whybudly h5{
    font-size: 16px;
  }
  .accordiontabsec .nav-tabs{
    position: static;
  }
  .requestbtn{
    font-size: 12px;
  }
  .MuiAutocomplete-root .MuiInputBase-input {
    font-size: 12px;
    min-height: 18px;
  }
  .row8{
    margin-left: -5px;
    margin-right: -5px;
  }
  .row8 > div{
    padding-left: 5px;
    padding-right: 5px;
  }
  h1 {
    font-size: 28px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3{
    font-size: 20px !important;
  }
  h4{
    font-size: 16px !important;
  }
  h5{
    font-size: 14px !important;
  }
  .accordiontabsec.tabcenter .nav-tabs a{
    width: 22%;
    font-size: 14px !important;
  }
  .accordiontabsec.tabcenter .nav-tabs a:after{
    height: 2px;
  }
  .brandcircleimg {
    width: 100px;
    height: 100px;
  }
  .brandcircleimg img{
    width: 80px;
    height: 80px;
  }
  .col5-mob33{
    -moz-box-flex: 0;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .border-md-right{
    border-right:none;
  }
  .redtbl table thead{
    display: none;
  }
  .testisec .swiper {
      padding: 100px 0 0 0;
  }
  .accmnu ul{
    flex-wrap: wrap;
    flex-direction: inherit;
  }
  .accmnu ul li{
    width: 31%;
  }
  .accmnu ul li a{
    background-color: #f1f1f1;
    font-size: 11px;
    white-space: nowrap;
    gap: 5px;
    padding: 8px 10px;
    border-radius: 5px;
  }
  .accmnu ul li a svg{
    width: 12px;
  }
  .accmnu ul li a span{
    width: 12px;
  }
  .ordrthmbwrp > div:nth-child(1){
    border-right: none;
    border-bottom: 1px solid #E9E9EB;
    padding-bottom: 5px;
  }
  .ordrthmbwrp > div:nth-child(2){
    border-right: none;
    border-bottom: 1px solid #E9E9EB;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .ordrthmbwrp > div:nth-child(3){
    border-right: none;
    border-bottom: 1px solid #E9E9EB;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .ordrthmbwrp > div:nth-child(4){
    padding-top: 5px;
  }
  .col5-mob50{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dplogos img{
    width: 120px !important;
    height: auto !important;
  }
  .testisec .testitxt {
    min-height: 110px;
  }
}

